import { ActivityType, type UserGroup, type UserRole } from '~/models'

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiY2FtZXJvbi1yZXNpbXBsaWZpIiwiYSI6ImNremc0cHI5aTAyNHgybnBocWV4NHNoY2MifQ.JAWWThmFY0TwUARWnv1ctA'

export const SERVE_ACCESS_TOKEN =
  'pk.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQ0FSTk0ifQ.KXEJ0TDslrQgb8sQjykUeB955-oVWouwjnK1CkuSxC4'

export const CLUSTER_ZOOM_LEVEL = 10

export const TYPES: { [key: string]: string } = {
  sale: 'Sale',
  lease: 'Lease',
  saleLease: 'Sale/Lease',
}

export const STATUSES: { [key: string]: string } = {
  deleted: 'Deleted',
  draft: 'Draft',
  active: 'Active',
  pending: 'Pending',
  sold: 'Sold',
  leased: 'Fully Leased',
  withdrawn: 'Withdrawn',
  expired: 'Expired',
}

export const ACTIVITY_LABELS: { [key: string]: string } = {
  [ActivityType.view]: 'View',
  [ActivityType.preview]: 'Preview',
  [ActivityType.download]: 'Download',
  [ActivityType.viewLink]: 'Opened Link',
  [ActivityType.update]: 'Updated',
  [ActivityType.create]: 'Created',
  [ActivityType.delete]: 'Deleted',
  [ActivityType.contact]: 'Contact',
}

export const USER_GROUPS: { [key in keyof typeof UserGroup]: string } = {
  superAdmin: 'Super Admin',
  organizationAdmin: 'Organization Admin',
  companyAdmin: 'Company Admin',
  appraiser: 'Appraiser',
  member: 'Member',
  // viewer: 'Viewer',
}

export const USER_ROLES: { [key in keyof typeof UserRole]: string } = {
  managingBroker: 'Managing Broker',
  coordinator: 'Transaction Coordinator',
  broker: 'Broker',
}

export const PROPERTY_TYPES_COLORS = [
  {
    value: 'orange',
    propertyType: 'Healthcare',
    className: 'bg-[#fb923c] hover:bg-[#fb923c]',
  },
  {
    value: 'pink',
    propertyType: 'Hospitality',
    className: 'bg-[#fb7185] hover:bg-[#fb7185]',
  },
  {
    value: 'slate',
    propertyType: 'Industrial',
    className: 'bg-[#475569] hover:bg-[#475569]',
  },
  {
    value: 'emerald',
    propertyType: 'Investment',
    className: 'bg-[#34d399] hover:bg-[#34d399]',
  },
  {
    value: 'indigo',
    propertyType: 'Land',
    className: 'bg-[#818cf8] hover:bg-[#818cf8]',
  },
  {
    value: 'fuchsia',
    propertyType: 'Multifamily',
    className: 'bg-[#e879f9] hover:bg-[#e879f9]',
  },
  {
    value: 'yellow',
    propertyType: 'Office',
    className: 'bg-[#fde047] hover:bg-[#fde047] text-[#030712]',
  },
  {
    value: 'cyan',
    propertyType: 'Retail',
    className: 'bg-[#22d3ee] hover:bg-[#22d3ee]',
  },
  {
    value: 'stone',
    propertyType: 'Other',
    className: 'bg-[#a8a29e] hover:bg-[#a8a29e]',
  },
]

export const PROPERTY_TYPES: string[] = [
  'Healthcare',
  'Hospitality',
  'Industrial',
  'Investment',
  'Land',
  'Multifamily',
  'Office',
  'Retail',
  'Other',
]

export const HOSPITALITY_SUBTYPES: string[] = [
  'Bed & Breakfast',
  'Casino',
  'Chalet',
  'Convention Center',
  'Extended Stay',
  'Golf Course',
  'Hostel',
  'Hotel',
  'Inn',
  'Motel',
  'Recreation Cabins',
  'Resort',
  'Ski & Sun',
  'Spa',
  'Vacation Rental(s)',
]

export const INDUSTRIAL_SUBTYPES: string[] = [
  'Cannabis',
  'Cold Storage',
  'Flex Space',
  'Food Processing',
  'Free Standing',
  'Industrial-Business Park',
  'Light Industrial',
  'Manufacturing',
  'Mixed Use',
  'Net Leased',
  'Office Showroom',
  'Research & Development',
  'Self Storage',
  'Truck Terminal',
  'Warehouse/Distribution',
  'Warehouse/Flex',
  'Warehouse/Office',
]

export const LAND_SUBTYPES: string[] = [
  'Agriculture',
  'Hospitality',
  'Industrial',
  'Mobile Home Park',
  'Multifamily',
  'Office',
  'Residential Development',
  'Retail',
  'Retail Pad',
  'Self Storage',
  'Vacation/Resort',
  'EV Charging Station',
]

export const MULTIFAMILY_SUBTYPES: string[] = [
  'Duplex/Quadplex',
  'Government Subsidized',
  'High Rise',
  'Low Rise',
  'Mid Rise',
  'Mixed Use',
  'Mobile Home Park',
  'Senior Living',
  'Student Housing',
  'Townhouse',
]

export const OFFICE_SUBTYPES: string[] = [
  'Business Park',
  'Executive Suites',
  'Coworking',
  'Data Center/Call Center',
  'Financial',
  'Governmental',
  'High-Tech',
  'Industrial',
  'Institutional',
  'Medical',
  'Mixed Use',
  'Flex Space',
  'Net Leased',
  'Office Building',
  'Research & Development',
]

export const RETAIL_SUBTYPES: string[] = [
  'Bank',
  'Big Box',
  'Cannabis Dispensary',
  'Car Wash',
  'Convenience Store',
  'Day Care Facility',
  'Free Standing',
  'Garden Center',
  'Hair Salon',
  'Mixed Use',
  'Movie Theater',
  'Post Office',
  'Restaurant',
  'Retail-Pad',
  'Service Station/Gas Station',
  'Showroom',
  'Street Retail',
  'Strip Center',
  'Tavern/Bar/Nightclub',
  'Vehicle Related',
]

export const OTHER_SUBTYPES: string[] = [
  'Airport',
  'Assembly/Meeting Place',
  'Church',
  'Funeral Home',
  'Hospital',
  'Parking Facility',
  'School',
  'Sports Center',
]

export const PROPERTY_SUBTYPE_MAP: { [key: string]: string[] } = {
  Hospitality: HOSPITALITY_SUBTYPES,
  Industrial: INDUSTRIAL_SUBTYPES,
  Land: LAND_SUBTYPES,
  Multifamily: MULTIFAMILY_SUBTYPES,
  Office: OFFICE_SUBTYPES,
  Retail: RETAIL_SUBTYPES,
  Other: OTHER_SUBTYPES,
}

export const RATE_TYPES: { [key: string]: string } = {
  sfYear: 'SF/Year',
  month: 'Month',
  year: 'Year',
  sfMonth: 'SF/Month',
  acMonth: 'Acre/Month',
  acYear: 'Acre/Year',
}

export const EMAIL_LISTING_PROJECT = {
  created: 1,
  modified: 1,
  name: 1,
  slug: 1,
  property: 1,
  type: 1,
  status: 1,
  propertyTypes: 1,
  images: { $slice: 1 },
  brokers: 1,
  company: 1,
}

// prettier-ignore
export const STATE_ABBRS = ['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VI','VA','WA','WV','WI','WY',]

export const ZONES: string[] = [
  'R-A',
  'R-1A',
  'R-1B',
  'R-1C',
  'R-1D',
  'R-T',
  'R-MC',
  'R-ML',
  'R-MH',
  'MX-T',
  'MX-L',
  'MX-M',
  'MX-H',
  'MX-FB-ID',
  'MX-FB-FX',
  'MX-FB-UD',
  'NR-C',
  'NR-BP',
  'NR-LM',
  'NR-GM',
  'NR-SU',
  'NR-PO-A',
  'NR-PO-B',
  'NR-PO-C',
  'NR-PO-D',
  'PD',
  'PC',
  'UNCL',
  'A-1 Rural Agricultural Zone',
  'A-2 Rural Agricultural Zone',
  'R-1 Single-Family Residential Zone',
  'R-2 Apartment Zone',
  'M-H Mobile Home and Single-Family Residential Zone',
  'O-1 Office and Institutional Zone',
  'C-N Neighborhood Commercial Zone',
  'C-1 Neighborhood Commercial Zone',
  'C-2 Community Commercial Zone',
  'C-LI Commercial/Light Industrial Zone',
  'M-1 Light Industrial Zone',
  'M-2 Heavy Manufacturing Zone',
  'OZ Overlay Zone',
  'SD Sector Development Zone',
]
